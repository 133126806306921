<template>
    <div>
        <ProductGroup
            :key="collection.id"
            :catalog-url="
                collection.category
                    ? `/camiseta/${collection.category.lang.link_rewrite}/`
                    : `/${collection.partner.url}`
            "
            :name="collection.category ? collection.category.lang.name : collection.partner.name"
            :products="collection.products"
            :show-only-thumb="true"
            list-class="product-horizontal-list product-horizontal-list-8 no-scrollbar"
            @scroll="scrollEvent = $event"
        />
        <HorizontalScrollbar
            :id-list="`featured-products-hs-${collection.id}`"
            :scroll-event="scrollEvent"
            class="hidden-not-touch"
        />
    </div>
</template>

<script>
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar';
    import ProductGroup from '../product/ProductGroup';

    export default {
        name: 'HomeWholesalerCollections',

        components: { HorizontalScrollbar, ProductGroup },

        props: {
            collection: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                scrollEvent: null,
            };
        },
    };
</script>
