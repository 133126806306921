import Vue from './common/vue-common';
import HorizontalScrollbar from './components/common/container/HorizontalScrollbar';
import { updateLazyLoad } from '../common/common';

export const getSimpleVueHorizontalScroll = (el, updateLazyLoading) =>
    new Vue({
        el,
        components: { HorizontalScrollbar },
        data() {
            return {
                scrollEvent: null,
            };
        },
        mounted() {
            if (updateLazyLoading) {
                updateLazyLoad();
            }
        },
    });
