<template>
    <div v-if="products">
        <h2 :class="{ 'section-title': stylizeTitle }">
            {{ title }}
        </h2>
        <ProductList
            :item-options="itemOptions"
            :list-class="`product-horizontal-list product-horizontal-list-${quantity} no-scrollbar`"
            :products="products"
            event-name="Última camisetas lançadas home"
            @scroll="scrollEvent = $event"
            id="last-released-wholesaler"
        />

        <HorizontalScrollbar id-list="last-released-wholesaler" :scroll-event="scrollEvent" />
    </div>
</template>

<script>
    import { homeWholesalerService } from '../../../common/service/resource';
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar';
    import ProductList from '../common/ProductList';

    export default {
        name: 'HomeWholesalerHighlights',

        components: { HorizontalScrollbar, ProductList },

        props: {
            title: {
                type: String,
                required: true,
            },
            quantity: {
                type: Number,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            stylizeTitle: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                scrollEvent: null,
                products: null,
                itemOptions: {
                    hideInstallments: true,
                    displayPercent: false,
                },
            };
        },

        created() {
            this.fetch();
        },

        methods: {
            fetch() {
                homeWholesalerService
                    .query()
                    .then((response) => {
                        if (response.data)
                            this.products = response.data.products_wholesalers.data.slice(
                                0,
                                this.quantity
                            );
                    })
                    .catch((error) => console.error(error));
            },
        },
    };
</script>
