<template>
    <Advantages :abtest="1" :hide-regulation="true" :is-home="true" />
</template>

<script>
    import Advantages from '../common/Advantages';

    export default {
        name: 'HomeCountdownAlternate1',

        el: '#vue-home-countdown-alternate-1',

        components: { Advantages },
    };
</script>
