<template>
    <Advantages :abtest="0" :hide-regulation="true" :is-home="true" />
</template>

<script>
    import Advantages from '../common/Advantages';

    export default {
        name: 'HomeCountdown',

        el: '#vue-home-countdown',

        components: { Advantages },
    };
</script>
