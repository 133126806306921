import Vue from './common/vue-common';
import Advantages from './components/common/Advantages.vue';

if (cr$.byId('vue-advantages').exists()) {
    new Vue({
        el: '#vue-advantages',
        template:
            '<Advantages :hide-promo-banner="($scope.HIDE_PROMO_BANNER && !$scope.view_counter)"/>',
        components: { Advantages },
    });
}
