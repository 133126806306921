<template>
    <div>
        <HomeWholesalerCollection
            v-for="collection in collections"
            :key="collection.id"
            :collection="collection"
        />
    </div>
</template>

<script>
    import ProductGroup from '../product/ProductGroup';
    import { objectPropertyComparator } from '../../../common/common';
    import { homeCollectionService } from '../../../common/service/resource';
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar';
    import HomeWholesalerCollection from './HomeWholesalerCollection';

    export default {
        name: 'HomeWholesalerCollections',

        components: { HomeWholesalerCollection },

        data() {
            return {
                collections: null,
            };
        },

        created() {
            this.fetchArtists();
        },

        methods: {
            fetchArtists() {
                homeCollectionService
                    .query({
                        limit_products: 8,
                        wholesaler: !this.$scope.IS_REGULAR_CUSTOMER,
                    })
                    .then((response) => {
                        this.collections = response.data
                            .filter((a) => a.products && a.products.length > 0)
                            .sort(objectPropertyComparator('name'));
                        if (this.collections.length === 0) {
                            cr$.byId('wholesaler-static-categories').removeClass('hidden');
                        } else {
                            cr$.byId('wholesaler-static-categories').addClass('hidden');
                        }
                    })
                    .catch(() => (this.errorLoading = true));
            },
        },
    };
</script>
