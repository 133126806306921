<template>
    <div v-if="products && products.length > 0">
        <h2 class="home-subtitle text-uppercase" v-if="params.scenario === 'home'">
            <span class="hidden-xs-down">Você vai curtir :)</span>
            <span class="hidden-xs-up">Cê vai curtir:</span>
        </h2>
        <h2 v-else-if="params.scenario === 'product-page'" class="h6 text-bold text-default-5 mb">
            Cê também vai gostar:
        </h2>
        <ProductList
            id="recommendation-list"
            :item-options="itemOptions"
            :list-class="
                listClass || `product-horizontal-list product-horizontal-list-4 no-scrollbar`
            "
            :products="products"
            :shorter-image="shorterImage"
            @scroll="scrollEvent = $event"
        />
        <HorizontalScrollbar
            v-if="products.length > 2"
            :scroll-event="scrollEvent"
            :button-height="300"
            :id-list="'recommendation-list'"
        />
    </div>
</template>

<script>
    import RequestsEmitter from '@bit/chicorei.chicomponents.util.requests-emitter';
    import ProductListItem from '../product/ProductListItem';
    import HorizontalScrollbar from './container/HorizontalScrollbar';
    import ProductList from './ProductList';

    export default {
        name: 'Recommendations',

        mixins: [RequestsEmitter],

        components: { ProductList, ProductListItem, HorizontalScrollbar },

        props: {
            params: {
                type: Object,
                required: true,
            },
            sliderOptions: {
                type: Object,
            },
            listEventName: {
                type: String,
                required: true,
            },
            itemOptions: Object,
            shorterImage: Boolean,
            listClass: String,
        },

        data() {
            return {
                products: null,
                scrollEvent: null,
                urlSearchParams: null,
                queries: null,
            };
        },

        computed: {
            innerItemOptions() {
                return {
                    displayPercent: false,
                    hideInstallments: true,
                    ...(this.itemOptions || {}),
                };
            },
        },

        created() {
            this.fetchData();
        },

        methods: {
            fetchData() {
                this.exposedRequests++;
                this.urlSearchParams = new URLSearchParams(window.location.search);
                this.queries = Object.fromEntries(this.urlSearchParams.entries());
                this.params = Object.assign(this.params, this.queries);
                this.axios.http
                    .get('/recommendation.php', { params: this.params })
                    .then((response) => {
                        if (response.data.products) {
                            this.products = response.data.products.data;
                            if (this.products.length > 0) {
                                this.$emit('success');
                            }
                        }
                    })
                    .catch((error) => console.error(error))
                    .then(() => this.exposedRequests--);
            },
        },
    };
</script>
